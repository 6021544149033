import { Box, Stack } from '@mui/material';
import { AvatarPair, Button, Chip, Text } from '@guider-global/ui';

export interface DashboardOnboardingViewProps {
  avatarA: string | React.ReactElement;
  avatarB: string | React.ReactElement;
  programLabel: string;
  heading: string;
  description: string;
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonCaption?: string;
  onSecondaryButtonClick?: () => void;
}

export function DashboardOnboardingView({
  avatarA,
  avatarB,
  programLabel,
  heading,
  description,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  secondaryButtonCaption,
  onSecondaryButtonClick,
}: DashboardOnboardingViewProps) {
  return (
    <Stack
      height="100%"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 4.5, md: 6 }}
    >
      <Box flex={4}>
        <AvatarPair
          avatarA={avatarA}
          avatarB={avatarB}
          sx={{ justifyContent: 'center' }}
        />
      </Box>
      <Stack height="100%" justifyContent="space-between" flex={5} gap={4.5}>
        <Stack
          alignItems={{ xs: 'center', md: 'flex-start' }}
          textAlign={{ xs: 'center', md: 'left' }}
          sx={{
            '.MuiChip-root': {
              borderRadius: '4px',
            },
          }}
        >
          <Chip label={programLabel} color="info" />
          <Text
            variant="h1"
            text={heading}
            color="white"
            lineHeight="35.2px"
            mt={1.5}
          />
          <Text
            variant="body1"
            text={description}
            color="white"
            lineHeight="21px"
            mt={1}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row-reverse' }}
          gap={{ xs: 3, md: 2 }}
        >
          <Button
            variant="contained"
            label={primaryButtonLabel}
            onClick={onPrimaryButtonClick}
            color="secondary"
            fullWidth
            sx={{ height: 'fit-content', flex: 1 }}
            data-cy="views_dashboard_relationshipCtaView_primary-button"
          />
          {secondaryButtonLabel && (
            <Stack
              alignItems={{ xs: 'center', md: 'flex-start' }}
              gap={{ xs: 1.25, md: 0 }}
              flex={1}
            >
              <Button
                variant="clear"
                label={secondaryButtonLabel}
                onClick={onSecondaryButtonClick}
                color="light"
                fullWidth
                sx={{ height: 'fit-content' }}
                data-cy="views_dashboard_relationshipCtaView_secondary-button"
              />
              {secondaryButtonCaption && (
                <Text
                  variant="caption"
                  text={secondaryButtonCaption}
                  color="white"
                  lineHeight={2}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
