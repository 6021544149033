export const prepareSanityString = (
  sanityString: string,
  variables: { [key: string]: string },
) => {
  let stringToReturn = sanityString;

  Object.entries(variables).forEach(([key, value]) => {
    const sanityKey = `{${key}}`;
    stringToReturn = stringToReturn.replace(sanityKey, value);
  });

  return stringToReturn;
};
