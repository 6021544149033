import { Button, Stack, AlertBox } from '@guider-global/ui';
import { Box as MuiBox } from '@mui/material';

export type AllCaughtUpViewProps = {
  title: string;
  description: string;
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
  tertiaryButtonLabel: string;
  onTertiaryButtonClick: () => void;
};

export function AllCaughtUpView({
  title,
  description,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  tertiaryButtonLabel,
  onTertiaryButtonClick,
}: Readonly<AllCaughtUpViewProps>) {
  return (
    <MuiBox>
      <AlertBox
        variant="icon"
        iconVariant="success"
        title={title}
        description={description}
        sx={{ color: 'white' }}
      />
      <Stack
        direction={{ xs: 'column', md: 'row-reverse' }}
        spacing={2}
        sx={{ mt: 5 }}
      >
        <Button
          label={primaryButtonLabel}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onPrimaryButtonClick}
        />
        <Button
          label={secondaryButtonLabel}
          fullWidth
          variant="outlined"
          color="light"
          onClick={onSecondaryButtonClick}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: { xs: 6, md: 14 } }}
      >
        <Button
          label={tertiaryButtonLabel}
          variant="clear"
          onClick={onTertiaryButtonClick}
          sx={{
            width: { xs: '100%', md: 'fit-content' },
            px: 2,
          }}
        />
      </Stack>
    </MuiBox>
  );
}
