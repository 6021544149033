import {
  CheckListItemProps,
  ProgressIndicatorDial,
  Stack,
  Text,
  TextProps,
  CheckList,
} from '@guider-global/ui';
import { Grid, useMediaQuery, useTheme, Box } from '@mui/material';
import { SecondaryPanelContent } from 'components/Dashboard';

export type OnboardingChecklistViewProps = {
  heading: string;
  percentCompleteLabel: string;
  progressDescription?: string;
  progressDialSize?: number;
  description?: string | TextProps;
  steps?: ({ percentCompletion: number } & CheckListItemProps)[];
};

export function OnboardingChecklistView({
  heading,
  progressDescription,
  description,
  steps = [],
}: OnboardingChecklistViewProps) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const percentageCompleted = steps
    .map((step) => {
      if (step.isChecked) {
        return step.percentCompletion;
      } else {
        return 0;
      }
    })
    .reduce((sum, currentValue) => sum + currentValue);

  const progressDialSize = isMd ? 12 : 8;

  const descriptionElement =
    typeof description === 'string' ? (
      <Text
        text={description}
        variant="caption"
        fontSize={{ xs: 12, md: 16 }}
      />
    ) : (
      <Text {...description} />
    );
  const showDescription = description !== undefined && isMd;

  return (
    <SecondaryPanelContent>
      {isMd ? undefined : (
        <Stack direction="column" spacing={0.5} py={2}>
          <Text variant="caption" text={heading} textAlign="center" />
          <Text
            variant="body1"
            text={`${percentageCompleted}%`}
            textAlign="center"
          />
        </Stack>
      )}
      <Grid container>
        <Grid item xs={4} md={12} order={{ xs: 1, md: 0 }}>
          <ProgressIndicatorDial
            metricLabel={`${percentageCompleted}%`}
            percentComplete={percentageCompleted}
            metricDescription={isMd ? progressDescription : undefined}
            size={progressDialSize}
            containerSx={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={8} md={12}>
          <Box
            px={{
              xs: 0,
              md: 2,
            }}
            pt={{ xs: 0, md: 1 }}
            pb={{ xs: 0, md: 3 }}
          >
            <Box px={1} pb={1} textAlign="left">
              {showDescription ? descriptionElement : undefined}
            </Box>
            <CheckList items={steps} />
          </Box>
        </Grid>
      </Grid>
    </SecondaryPanelContent>
  );
}
