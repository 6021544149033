import { GridTable, Stack, Text, TextStack } from '@guider-global/ui';
import { GuideAvatar } from 'components';

export interface IDashbordRelationshipsRow {
  name: string;
  userPicture?: string;
  profilePicture?: string;
  description?: string;
  numberOfSessions: string;
  nextSessionLabel: string;
  nextSessionColor: string;
  onClick: () => void;
}
export interface IDashbordRelationshipsHeaders {
  name: string;
  numberOfSessions: string;
  nextSession: string;
}
export interface ProgramMembershipTableViewProps {
  rows: IDashbordRelationshipsRow[];
  headers: IDashbordRelationshipsHeaders;
}

export function ProgramMembershipTableView({
  rows,
  headers,
}: ProgramMembershipTableViewProps) {
  // Table header

  return (
    <GridTable
      rowsPerPage={20}
      header={{
        cells: [
          {
            key: 'name',
            content: <Text text={headers.name} />,
            xs: 5,
          },
          {
            key: 'sessions_number',
            content: <Text text={headers.numberOfSessions} />,
            xs: 3,
          },
          {
            key: 'next_session',
            content: <Text text={headers.nextSession} />,
            xs: 4,
          },
        ],
        sx: {
          backgroundColor: (theme) => theme.palette.background.default,
          py: 2,
          px: 3,
          textAlign: 'left',
        },
      }}
      rows={rows.map((row) => {
        return {
          onClick: row.onClick,

          cells: [
            {
              key: 'name',
              content: (
                <Stack direction="row" spacing={1}>
                  <GuideAvatar
                    width={32}
                    height={32}
                    profilePicture={row.profilePicture}
                    userPicture={row.userPicture}
                  />
                  <TextStack
                    size="xs"
                    heading={row.name}
                    sx={{ wordBreak: 'break-word' }}
                    subtitles={[row.description]}
                  />
                </Stack>
              ),
              xs: 5,
              pr: 2,
            },
            {
              key: 'session_number',
              content: <Text text={row.numberOfSessions.toString() ?? '0'} />,
              xs: 3,
            },
            {
              key: 'next_session',
              content: (
                <Text
                  text={row.nextSessionLabel}
                  color={row.nextSessionColor}
                />
              ),

              xs: 4,
            },
          ],
        };
      })}
      rowProps={{
        sx: {
          color: (theme) => theme.palette.text.secondary,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          px: 3,
          py: 2,
          cursor: 'pointer',
          textAlign: 'left',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.paper.hover.light,
          },
        },
      }}
      sx={{
        border: (theme) => `1px solid ${theme.palette.paper.border.primary}`,
        borderRadius: '8px',
        boxShadow: 'none',
      }}
      showNavigator={false}
    />
  );
}
