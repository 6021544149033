import { Button } from '@guider-global/ui';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export type NotificationHighlightsViewProps = {
  children: ReactNode;
  buttonLabel: string;
  onButtonClick: () => void;
};

export function NotificationHighlightsView({
  children,
  buttonLabel,
  onButtonClick,
}: Readonly<NotificationHighlightsViewProps>) {
  return (
    <Stack
      height="100%"
      spacing={2}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="column" spacing={1} width="100%">
        {children}
      </Stack>
      <Button variant="clear" label={buttonLabel} onClick={onButtonClick} />
    </Stack>
  );
}
