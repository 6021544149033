import { KeyedProps } from '@guider-global/shared-types';
import {
  Button,
  Divider,
  KeyStat,
  KeyStatProps,
  Leaderboard,
  LeaderboardRow,
  ProgressIndicatorDial,
  Stack,
  Text,
} from '@guider-global/ui';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SecondaryPanelContent } from 'components/Dashboard';

export type ProgressLeaderboardCardViewProps = {
  keyStats: KeyedProps<KeyStatProps>[];
  dialSize?: number;
  dialMetricLabel: string;
  dialMetricDescription: string;
  leaderboardHeading: string;
  leaderboardCaption?: string;
  leaderboardRows: LeaderboardRow[];
  selectedLeaderboardRow?: number;
  viewLeaderboardLabel: string;
  onViewLeaderboard?: () => void;
  showLeaderboard?: boolean;
};

export function ProgressLeaderboardCardView({
  keyStats,
  dialSize = 12,
  dialMetricLabel,
  dialMetricDescription,
  leaderboardHeading,
  leaderboardCaption,
  leaderboardRows,
  selectedLeaderboardRow,
  viewLeaderboardLabel,
  onViewLeaderboard,
  showLeaderboard,
}: ProgressLeaderboardCardViewProps) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SecondaryPanelContent>
      <Stack direction="column" py={2} px={2}>
        <Box px={1} py={1} textAlign="center">
          <Stack direction="row" spacing={3} align="center">
            {keyStats.map((stat, index) => (
              <KeyStat
                key={stat.key}
                value={stat.value}
                label={stat.label}
                align="center"
                divider={index > 0 ? true : false}
                underline={isMd ? 'secondary' : false}
              />
            ))}
          </Stack>
          <ProgressIndicatorDial
            percentComplete={100}
            size={dialSize}
            metricLabel={dialMetricLabel}
            metricDescription={isMd ? dialMetricDescription : undefined}
            containerSx={{ mt: 2 }}
          />
        </Box>
        {showLeaderboard ? (
          <Box>
            <Divider sx={{ alignSelf: 'center', my: 2 }} />
            <Stack direction="row" px={1}>
              <Text text={leaderboardHeading} variant="h3" />
              {leaderboardCaption ? (
                <Text text={leaderboardCaption} variant="caption" />
              ) : (
                <></>
              )}
            </Stack>
            <Box px={1} py={1}>
              <Leaderboard
                rows={leaderboardRows}
                rowsPerPage={3}
                showNavigator={false}
                selected={selectedLeaderboardRow}
              />
            </Box>
            <Stack direction="row" justifyContent="end">
              <Button
                startIcon={<BarChartIcon />}
                label={viewLeaderboardLabel}
                variant="text"
                color="info"
                onClick={onViewLeaderboard}
              />
            </Stack>
          </Box>
        ) : undefined}
      </Stack>
    </SecondaryPanelContent>
  );
}
