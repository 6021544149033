import { Button, ButtonProps, ChipProps, TextStack } from '@guider-global/ui';

import { Stack } from '@mui/system';

export type ProgramMembershipDetailsViewProps = {
  title: string;
  description: string;
  fields: { label: string; value: string }[];
  chip?: ChipProps;
  button?: ButtonProps;
};

export function ProgramMembershipDetailsView({
  title,
  description,
  fields,
  chip,
  button,
}: ProgramMembershipDetailsViewProps) {
  return (
    <Stack direction={'column'} width={{ xs: '100%', lg: '33%' }} gap={4}>
      <TextStack
        spacing={1}
        size="small"
        heading={title}
        subtitles={[description]}
        chip={
          chip
            ? {
                position: 'right',
                label: chip?.label,
                color: chip?.color,
              }
            : undefined
        }
      />
      {fields?.map((field, index) => (
        <TextStack
          key={`membership-field-${index}`}
          size="xs"
          heading={field.label}
          subtitles={[field.value]}
        />
      ))}
      {button && (
        <Button
          variant="outlined"
          color="info"
          {...button}
          sx={{ mt: 'auto', ...button.sx }}
        />
      )}
    </Stack>
  );
}
