export function sanityFieldToOptionsArray(field: object): {
  label: never;
  value: never;
}[] {
  if (field) {
    const object = field;
    const keys = Object.keys(object) as (keyof typeof object)[];
    const options = keys.map((key) => {
      return { label: object[key], value: key };
    });
    return options;
  } else {
    return [];
  }
}
