import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { ActionBox, ResourceCard, Stack, Text } from '@guider-global/ui';
import { Divider, useMediaQuery, useTheme } from '@mui/material';

export type ProgramCtaProgram = {
  name: string;
  description: string | undefined;
  image: string | undefined;
  type: string | undefined;
  displayPreference: 'center' | 'top' | 'left' | 'right' | 'bottom' | undefined;
  onClick: () => void;
};

export type ProgramCtaViewProps = {
  programs: ProgramCtaProgram[];
};

export function ProgramCtaView({ programs }: ProgramCtaViewProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Sanity
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const buttonLabel =
    baseLanguage?.dashboard?.onboarding?.steps?.join_a_program
      ?.join_a_program_button_label;
  const ctaCaption =
    baseLanguage?.dashboard?.onboarding?.steps?.join_a_program
      ?.join_a_program_button_description;

  const programA = programs.at(0);
  const programB = programs.at(1);

  if (!programA) {
    return <></>;
  }

  if (!programB) {
    return (
      <ActionBox
        orientation={isMobile ? 'vertical' : 'horizontal'}
        title={{
          text: programA.name,
          lineHeight: '1.4',
          variant: 'h2',
          component: 'p',
        }}
        descriptions={programA.description ? [programA.description] : undefined}
        imageSrc={programA.image}
        subtitle={ctaCaption}
        chip={{ label: programA.type ?? '', color: 'info' }}
        buttons={[
          {
            label: buttonLabel,
            onClick: programA.onClick,
            variant: 'contained',
            color: 'secondary',
            key: 'join-program-button',
          },
        ]}
        sx={{
          px: 1,
          height: 'fit-content',
          color: 'white',
          textAlign: isMobile ? 'center' : 'left',
        }}
      />
    );
  }

  return (
    <Stack direction="column" alignItems="center" spacing={1}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={3}
        width="100%"
        pb={2}
      >
        <ResourceCard
          key="program-a"
          title={programA.name}
          description={programA.description}
          imageSrc={programA.image}
          imagePosition={programA.displayPreference ?? 'center'}
          buttons={[
            {
              label: buttonLabel,
              onClick: programA.onClick,
              variant: 'contained',
              color: 'info',
              key: 'join-program-button-one',
            },
          ]}
          sx={{ flex: '1 1 0', border: 'none' }}
        />
        {isMobile && <Divider sx={{ borderColor: '#FFFFFF' }} />}
        <ResourceCard
          key="program-b"
          title={programB.name}
          description={programB.description}
          imageSrc={programB.image}
          imagePosition={programB.displayPreference ?? 'center'}
          buttons={[
            {
              label: buttonLabel,
              onClick: programB.onClick,
              variant: 'contained',
              color: 'info',
              key: 'join-program-button-two',
            },
          ]}
          sx={{ flex: '1 1 0', border: 'none' }}
        />
      </Stack>
      {!isMobile && <Text text={ctaCaption} variant="caption" color="white" />}
    </Stack>
  );
}
