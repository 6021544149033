import {
  IProfile,
  SanityPersonalDetails,
  SanityProfileField,
  ICustomField,
  SanityOrganization,
  SanityAdditionalAgreement,
} from '@guider-global/shared-types';
import { getAgreementNames } from 'utils/getAgreementNames';
import { snakeToCamelCase } from 'utils/snakeToCamelCase';

type IProfileKeys = Array<keyof IProfile>;
type SanityPersonalDetailsKeys = Array<keyof SanityPersonalDetails>;

interface IGetRequiredFieldProps {
  sanityOrganizationPersonalDetails: SanityPersonalDetails;
  organizationAgreements: SanityAdditionalAgreement[] | undefined;
  sanityOrganizationProfileFields: SanityProfileField[];
  organizationSlug: string;
}

interface IGetIncompleteProfileFieldsProps {
  sanityOrganization: SanityOrganization;
  organizationAgreements: SanityAdditionalAgreement[] | undefined;
  profile: IProfile;
}

const getRequiredFields = ({
  sanityOrganizationPersonalDetails,
  organizationAgreements,
  sanityOrganizationProfileFields,
  organizationSlug,
}: IGetRequiredFieldProps) => {
  const organizationProfileFieldKeys = Object.keys(
    sanityOrganizationPersonalDetails,
  ) as SanityPersonalDetailsKeys;
  const isOrganizationProfileFieldKeys =
    organizationProfileFieldKeys.length > 0;

  const organizationRequiredProfileFieldKeys = isOrganizationProfileFieldKeys
    ? organizationProfileFieldKeys.reduce((prev: string[], key) => {
        const formatKey =
          key === 'linkedin_url' ? 'linkedInUrl' : snakeToCamelCase(key);

        const field = sanityOrganizationPersonalDetails[key];
        if (field.enabled && field.required) return [...prev, formatKey];
        return prev;
      }, [])
    : [];

  const organizationRequiredCustomProfileFieldKeys =
    sanityOrganizationProfileFields.reduce(
      (prev: string[], customProfileField) => {
        const required = customProfileField.required;
        const id = customProfileField.id.current;
        if (required) return [...prev, id];
        return prev;
      },
      [],
    );

  const organizationCustomAgreementsFieldKeys = organizationAgreements?.map(
    (agreement) => {
      const {
        agreement_name: { current },
        _key,
      } = agreement;

      const { slug } = getAgreementNames({
        name: current,
        id: _key,
      });
      return slug;
    },
  );

  const requiredFields = [
    ...organizationRequiredProfileFieldKeys,
    ...organizationRequiredCustomProfileFieldKeys,
    ...(organizationCustomAgreementsFieldKeys ?? []),
  ];
  return requiredFields;
};

const getCompletedProfileFields = (profile: IProfile) => {
  const profileKeys = Object.keys(profile) as IProfileKeys;
  const profileCustomFields = profile.organizationFields as ICustomField[];

  const completedProfileFields = profileKeys.reduce(
    (prev: IProfileKeys, key) => {
      const field = profile[key];
      if (field) return [...prev, key];
      return prev;
    },
    [],
  );
  const completedCustomProfileFields = profileCustomFields.map(
    (customField) => {
      return customField.fieldSlug;
    },
  );
  const completedFields = [
    ...completedProfileFields,
    ...completedCustomProfileFields,
  ];
  return completedFields;
};

export const getIncompleteProfileFields = ({
  profile,
  sanityOrganization,
  organizationAgreements,
}: IGetIncompleteProfileFieldsProps) => {
  const sanityOrganizationProfileFields =
    sanityOrganization.profile_fields ?? [];
  const sanityOrganizationPersonalDetails = sanityOrganization.personal_details;
  const requiredFields = getRequiredFields({
    sanityOrganizationPersonalDetails,
    organizationAgreements,
    sanityOrganizationProfileFields,
    organizationSlug: sanityOrganization.basic_info.subdomain.current,
  });

  const completedFields = getCompletedProfileFields(profile);

  const incompleteFields = requiredFields.filter((requiredField) => {
    const isCompleted = completedFields.includes(requiredField);
    if (!isCompleted) return requiredField;
    return undefined;
  });

  return incompleteFields;
};
