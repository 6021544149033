import { Card, Text } from '@guider-global/ui';
import { Box as MuiBox } from '@mui/material';
import backgroundImage from 'assets/dashboard-notifications-background.svg';
import { ReactNode } from 'react';

export type NotificationPanelViewProps = {
  title: string;
  children: ReactNode;
};

export function NotificationPanelView({
  title,
  children,
}: NotificationPanelViewProps) {
  return (
    <MuiBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 1 0',
      }}
    >
      <Text
        variant="h4"
        text={title}
        align="center"
        sx={{
          m: 0,
          mb: 1,
          ml: { xs: 2, md: 0 },
        }}
        color="#FFFFFF"
      />

      <Card
        sx={{
          px: { xs: 2, md: 5 },
          py: { xs: 5, md: 5 },
          flexGrow: 1,
          width: '100%',
          borderRadius: {
            xs: 0,
            md: '8px',
          },
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          boxShadow: '0px 8px 16px 0px #00000026',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {children}
      </Card>
    </MuiBox>
  );
}
