// external
import {
  Action,
  ThunkAction,
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';

// store
import appReducer from 'store/slices/appSlice';
import formsReducer from 'store/slices/formsSlice';
import userReducer from 'store/slices/userSlice';
import availabilitiesSlice from './slices/availabilitiesSlice';
import chatParticipantsSlice from './slices/chatParticipantsSlice';
import customFieldsSlice from './slices/customFieldsSlice';
import eventsSlice from './slices/eventsSlice';
import goalsSlice from './slices/goalsSlice';
import userIntegrationsSlice from './slices/userIntegrationsSlice';
import matchesSlice from './slices/matchesSlice';
import membershipsSlice from './slices/membershipsSlice';
import metricsSlice from './slices/metricsSlice';
import metricsTokensSlice from './slices/metricsTokensSlice';
import notesSlice from './slices/notesSlice';
import organizationsSlice from './slices/organizationsSlice';
import profilesSlice from './slices/profilesSlice';
import relationshipThreadsSlice from './slices/relationshipThreadsSlice';
import relationshipsSlice from './slices/relationshipsSlice';
import sanityOrganizationSlice from './slices/sanityOrganizationSlice';
import sessionsSlice from './slices/sessionsSlice';
import skillsSlice from './slices/skillsSlice';
import usersSlice from './slices/usersSlice';
import videoRoomReducer from './slices/videoRoomSlice';
import videoSlice from './slices/videoSlice';
import videoTokenSlice from './slices/videoTokenSlice';

// types
import {
  sanityBaseLanguageSlice,
  sanityOrganizationProgramsSlice,
  sanityLocalizationSlice,
  sanitySettingsSlice,
} from '@guider-global/sanity-hooks';
import {
  IAvailability,
  IChatParticipant,
  ICustomField,
  IEvent,
  IGoal,
  UserIntegration,
  ILeaderboardPosition,
  IMatch,
  IMembership,
  IMetric,
  IMetricToken,
  INote,
  INotification,
  IOrganization,
  IProfile,
  IRelationship,
  ISession,
  ISetting,
  ISkill,
  TSurvey,
  ITokenVideo,
  IUser,
  IUserPicture,
  IVideo,
} from '@guider-global/shared-types';
import leaderboardPositionsSlice from './slices/leaderboardPositionsSlice';
import notificationsSlice from './slices/notificationsSlice';
import settingsSlice from './slices/settingsSlice';
import userPicturesSlice from './slices/userPicturesSlice';
import { storageTokenSlice } from '@guider-global/azure-storage-hooks';
import modalNotificationsSlice from './slices/modalNotificationsSlice';
import surveysSlice from './slices/surveysSlice';

export const serializableStateInvariantMiddleware =
  createSerializableStateInvariantMiddleware({
    ignoredPaths: ['app.navbar.unauthenticatedActions', 'relationshipThreads'],
    ignoredActions: [
      'app/setNavbarUnauthenticatedActions',
      'relationshipThreads/updateRelationshipThreads',
    ],
  });

export const mainReducer = {
  app: appReducer,
  sanitySettings: sanitySettingsSlice.reducer,
  user: userReducer,
  forms: formsReducer,
  videoRoom: videoRoomReducer,
  userIntegrations: userIntegrationsSlice.reducer,
  relationshipThreads: relationshipThreadsSlice,
  storageToken: storageTokenSlice.reducer,
  // !! Generated reducers
  sanityBaseLanguage: sanityBaseLanguageSlice.reducer,
  sanityOrganization: sanityOrganizationSlice.reducer,
  sanityOrganizationPrograms: sanityOrganizationProgramsSlice.reducer,
  sanityLocalization: sanityLocalizationSlice.reducer,

  // !! Generated rest reducers
  userPictures: userPicturesSlice.reducer,
  availabilities: availabilitiesSlice.reducer,
  customFields: customFieldsSlice.reducer,
  events: eventsSlice.reducer,
  goals: goalsSlice.reducer,
  leaderboardPositions: leaderboardPositionsSlice.reducer,
  matches: matchesSlice.reducer,
  memberships: membershipsSlice.reducer,
  metrics: metricsSlice.reducer,
  metricsTokens: metricsTokensSlice.reducer,
  chatParticipants: chatParticipantsSlice.reducer,
  notes: notesSlice.reducer,
  notifications: notificationsSlice.reducer,
  modalNotifications: modalNotificationsSlice.reducer,
  organizations: organizationsSlice.reducer,
  profiles: profilesSlice.reducer,
  relationships: relationshipsSlice.reducer,
  sessions: sessionsSlice.reducer,
  settings: settingsSlice.reducer,
  skills: skillsSlice.reducer,
  users: usersSlice.reducer,
  video: videoSlice.reducer,
  videoToken: videoTokenSlice.reducer,
  surveys: surveysSlice.reducer,
};

/**
 * The redux state that defines which reducers are RestRootState reducers
 */
export type RestRootState = Pick<
  ReturnType<typeof store.getState>,
  // !! Generated rest reducers names
  | 'userPictures'
  | 'availabilities'
  | 'goals'
  | 'userIntegrations'
  | 'sessions'
  | 'profiles'
  | 'users'
  | 'relationships'
  | 'customFields'
  | 'organizations'
  | 'skills'
  | 'leaderboardPositions'
  | 'memberships'
  | 'matches'
  | 'notes'
  | 'notifications'
  | 'video'
  | 'videoToken'
  | 'metrics'
  | 'metricsTokens'
  | 'events'
  | 'chatParticipants'
  | 'settings'
  | 'modalNotifications'
  | 'surveys'
>;

export type RestRootStateTypes =
  // !! Generated rest reducers types
  | IUserPicture
  | IAvailability
  | ISession
  | IGoal
  | UserIntegration
  | IProfile
  | IUser
  | IRelationship
  | IRelationship
  | ICustomField
  | IOrganization
  | ISkill
  | ILeaderboardPosition
  | IMembership
  | IMatch
  | INote
  | INotification
  | IVideo
  | ITokenVideo
  | IMetric
  | IMetricToken
  | IEvent
  | IChatParticipant
  | ISetting
  | TSurvey;

export const store = configureStore({
  reducer: mainReducer,
  middleware: [serializableStateInvariantMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
