import {
  Image,
  Stack,
  TextStack,
  ButtonProps,
  ButtonStack,
} from '@guider-global/ui';

export type ProgramMembershipEmptyTableViewProps = {
  heading: string;
  descriptions: string[];
  leftButton: ButtonProps;
  rightButton: ButtonProps;
  imageUrl?: string;
};

export function ProgramMembershipEmptyTableView({
  heading,
  descriptions,
  leftButton,
  rightButton,
  imageUrl,
}: ProgramMembershipEmptyTableViewProps) {
  return (
    <Stack
      direction="column"
      align="center"
      spacing={2}
      sx={{
        py: 6,
        border: (theme) => `1px solid ${theme.palette.paper.border.primary}`,
        borderRadius: 1,
        px: 2,
      }}
    >
      {imageUrl && <Image src={imageUrl} width="180px" />}
      <TextStack
        size="medium"
        align="center"
        heading={heading}
        spacing={1}
        subtitles={descriptions}
        sx={{ textAlign: 'center' }}
      />
      <ButtonStack
        buttonsWidth="100%"
        buttonsColor="info"
        direction={{ xs: 'column', md: 'row' }}
        width={{ xs: 'auto', md: '450px' }}
        buttons={[
          {
            variant: 'contained',
            ...leftButton,
            key: 'no-relationships-button-left',
          },
          {
            variant: 'outlined',
            ...rightButton,
            key: 'no-relationships-button-right',
          },
        ]}
      />
    </Stack>
  );
}
