/* eslint-disable camelcase */

// external
import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import {
  SanityOrganization,
  SanityOrganizationWhiteLabelLogoSize,
} from '@guider-global/shared-types';
import {
  CreateReduxSlice,
  createReduxSlice,
  IReduxState,
} from '@guider-global/redux-axios-hooks';
import { RootState } from 'store';

export interface SanityOrganizationState extends IReduxState {
  organization: SanityOrganization;
  languageCode: string;
  countryCode: string;
}

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const initialSanityOrganizationState: SanityOrganizationState = {
  loading: false,
  success: false,
  error: false,
  errors: [],
  organization: {
    _id: '',
    deployment_status: {
      subdomain_deployment_status: {
        status_type: '',
        subdomain_status: '',
        auth0_status: '',
      },
    },
    access: {
      local_account_registration: false,
    },
    basic_info: {
      database_region: '',
      default_user_language: { _ref: '', _type: 'reference' },
      limited_licenses: false,
      number_of_licenses: undefined,
      name: '',
      subdomain: {
        current: '',
      },
      auth0_organization_id: '',
    },
    white_label: {
      additional_agreements: [],
      color_palette_config: {
        color_palette_enabled: false,
        color_palette: undefined,
      },
      logo: {
        _type: 'image',
        asset: {
          _ref: '',
          _type: 'reference',
        },
      },
      logo_size: SanityOrganizationWhiteLabelLogoSize.Default,
      auth0_logo: {
        _type: 'image',
        asset: {
          _ref: '',
          _type: 'reference',
        },
      },
      registration_logo: {
        _type: 'image',
        asset: {
          _ref: '',
          _type: 'reference',
        },
      },
      additional_nav_links: [],
      integrations: {
        video_conferencing: {
          active_directory_teams: false,
          guider_video: false,
        },
        calendar: {
          active_directory_outlook_calendar: false,
        },
      },
    },
    landing_page: {
      enable_landing_page: false,
      hero: {
        dynamic_title: false,
        title: undefined,
        title_prefix: undefined,
        dynamic_list: undefined,
        title_suffix: undefined,
        subtitle: '',
        button_1_label: '',
        button_2_label: '',
        button_2_enable: false,
      },

      content: [],
    },
    learning_hub: {
      navigation_label: '',
      welcome_message: {
        title: '',
        subtitle: '',
      },
      layout: [],
    },
    dashboard: {
      leaderboard: false,
      top_resources: {
        content_selection_type: '',
        content_selection: [],
      },
    },
    personal_details: {
      gender: {
        enabled: false,
        required: false,
      },
      town_or_city: {
        enabled: false,
        required: false,
      },
      country: {
        enabled: false,
        required: false,
      },
      job_title: {
        enabled: false,
        required: false,
      },
      date_of_birth: {
        enabled: false,
        required: false,
      },
      pronouns: {
        enabled: false,
        required: false,
      },
      linkedin_url: {
        enabled: false,
        required: false,
      },
    },
    profile_fields: [],
    goal_categories: {
      goal_categories: [],
    },
    metrics: [],
    availabilities: {
      start_time: '',
      end_time: '',
    },
  },
  languageCode: '',
  countryCode: '',
};

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const slice: CreateReduxSlice<
  SanityOrganizationState,
  SliceCaseReducers<SanityOrganizationState>,
  'sanityOrganization'
> = createReduxSlice({
  name: 'sanityOrganization',
  initialState: initialSanityOrganizationState,
  reducers: {
    setOrganization(state, { payload }: PayloadAction<SanityOrganization>) {
      return {
        ...state,
        organization: { ...payload },
      };
    },
    setOrganizationLanguageCode: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        languageCode: payload,
      };
    },
    setOrganizationCountryCode: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        countryCode: payload,
      };
    },
  },
});

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
const { actions, reducer } = slice;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
const selector: (state: SanityOrganizationState) => SanityOrganizationState = (
  state: SanityOrganizationState,
) => state;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationGoalCategories = (state: RootState) =>
  state.sanityOrganization.organization.goal_categories.goal_categories;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationLandingPage = (state: RootState) =>
  state.sanityOrganization.organization.landing_page;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationWhiteLabel = (state: RootState) =>
  state.sanityOrganization.organization.white_label;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationPersonalDetails = (state: RootState) =>
  state.sanityOrganization.organization.personal_details;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationProfileFields = (state: RootState) =>
  state.sanityOrganization.organization.profile_fields;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationBasicInfo = (state: RootState) =>
  state.sanityOrganization.organization.basic_info;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationSlug = (state: RootState) =>
  state.sanityOrganization.organization.basic_info.subdomain.current;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationName = (state: RootState) =>
  state.sanityOrganization.organization.basic_info.name;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationId = (state: RootState) =>
  state.sanityOrganization.organization._id;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectAuth0OrganizationId = (state: RootState) =>
  state.sanityOrganization.organization.basic_info.auth0_organization_id;

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
export const selectOrganizationLocalizationCodes = (state: RootState) => {
  const { languageCode, countryCode } = state.sanityOrganization;
  return { languageCode, countryCode };
};

/**
 * @deprecated Use @guider-global/sanity-hooks/hooks/useSanityOrganization instead.
 */
const sanityOrganizationState = {
  initialState: initialSanityOrganizationState,
  actions,
  reducer,
  selector,
};
export default sanityOrganizationState;
