import { RegistrationTypes } from 'store/slices/appSlice';

export function getRegistrationMethod(
  registrationType: RegistrationTypes,
): string | null {
  switch (registrationType) {
    case 'localAccounts':
      return 'Local account';
    case 'localAccountsAndSSO':
      return 'SSO, Local account';
    case 'multitenantsSSO':
      return 'SSO, Multitenants';
    case 'none':
    default:
      return null;
  }
}
