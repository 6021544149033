import { AvatarBox, CollapsibleCard, Divider, Stack } from '@guider-global/ui';
import {
  ProgramMembershipDetailsView,
  ProgramMembershipDetailsViewProps,
} from '../ProgramMembershipDetailsView';
import {
  IDashbordRelationshipsHeaders,
  IDashbordRelationshipsRow,
  ProgramMembershipTableView,
} from '../ProgramMembershipTableView';
import {
  ProgramMembershipEmptyTableView,
  ProgramMembershipEmptyTableViewProps,
} from '../ProgramMembershipEmptyTableView';
export interface ProgramMembershipsGuideContent {
  guideMembershipDetails?: ProgramMembershipDetailsViewProps;
  guideRelationshipRows: IDashbordRelationshipsRow[];
  guideRelationshipsEmptyState?: ProgramMembershipEmptyTableViewProps;
}
export interface ProgramMembershipsTraineeContent {
  traineeMembershipDetails?: ProgramMembershipDetailsViewProps;
  traineeRelationshipRows: IDashbordRelationshipsRow[];
  traineeRelationshipsEmptyState?: ProgramMembershipEmptyTableViewProps;
}

export type ProgramMembershipsCardProps = {
  title: string;
  description?: string;
  avatarSrc?: string;
  guideMembership: ProgramMembershipsGuideContent;
  traineeMembership: ProgramMembershipsTraineeContent;
  relationshipsEmptyStateAvatar?: string;
  relationshipsTableHeaders: IDashbordRelationshipsHeaders;
};

export function ProgramMembershipsCard({
  title,
  description,
  avatarSrc,
  relationshipsEmptyStateAvatar,
  guideMembership,
  traineeMembership,
  relationshipsTableHeaders,
}: ProgramMembershipsCardProps) {
  const {
    traineeMembershipDetails,
    traineeRelationshipRows,
    traineeRelationshipsEmptyState,
  } = traineeMembership;

  const {
    guideMembershipDetails,
    guideRelationshipRows,
    guideRelationshipsEmptyState,
  } = guideMembership;

  return (
    <CollapsibleCard
      headerElement={
        <AvatarBox
          size="medium"
          avatarSrc={avatarSrc}
          heading={title}
          subtitles={[description]}
        />
      }
      sx={{
        borderRadius: {
          xs: 0,
          md: '8px',
          boxShadow: '0px 8px 16px 0px #00000026',
        },
      }}
    >
      {traineeMembershipDetails && (
        <Stack direction={{ xs: 'column', lg: 'row' }} p={4} gap={7}>
          <ProgramMembershipDetailsView
            title={traineeMembershipDetails.title}
            description={traineeMembershipDetails.description}
            fields={traineeMembershipDetails.fields}
            button={traineeMembershipDetails.button}
          />
          <Stack direction={'column'} flex={1}>
            {traineeRelationshipRows.length > 0 && (
              <ProgramMembershipTableView
                rows={traineeRelationshipRows}
                headers={relationshipsTableHeaders}
              />
            )}
            {traineeRelationshipsEmptyState && (
              <ProgramMembershipEmptyTableView
                imageUrl={relationshipsEmptyStateAvatar}
                heading={traineeRelationshipsEmptyState?.heading}
                descriptions={traineeRelationshipsEmptyState?.descriptions}
                leftButton={traineeRelationshipsEmptyState.leftButton}
                rightButton={traineeRelationshipsEmptyState.rightButton}
              />
            )}
          </Stack>
        </Stack>
      )}

      {guideMembershipDetails && (
        <>
          <Divider />
          <Stack direction={{ xs: 'column', lg: 'row' }} p={4} gap={7}>
            <ProgramMembershipDetailsView
              title={guideMembershipDetails.title}
              description={guideMembershipDetails.description}
              fields={guideMembershipDetails.fields}
              chip={guideMembershipDetails.chip}
              button={guideMembershipDetails.button}
            />
            <Stack direction={'column'} flex={1}>
              {guideRelationshipRows.length > 0 && (
                <ProgramMembershipTableView
                  rows={guideRelationshipRows}
                  headers={relationshipsTableHeaders}
                />
              )}
              {guideRelationshipsEmptyState && (
                <ProgramMembershipEmptyTableView
                  imageUrl={relationshipsEmptyStateAvatar}
                  heading={guideRelationshipsEmptyState.heading}
                  descriptions={guideRelationshipsEmptyState.descriptions}
                  leftButton={guideRelationshipsEmptyState.leftButton}
                  rightButton={guideRelationshipsEmptyState.rightButton}
                />
              )}
            </Stack>
          </Stack>
        </>
      )}
    </CollapsibleCard>
  );
}
