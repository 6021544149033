import { ReactNode } from 'react';
import { BasicModal, Button, IconButton, Stack, Text } from '@guider-global/ui';
import Close from '@mui/icons-material/Close';

export type NotificationModalViewProps = {
  open?: boolean;
  headingText: string;
  closeButtonLabel: string;
  onClose?: () => void;
  children: ReactNode;
};

export function NotificationModalView({
  open,
  headingText,
  closeButtonLabel,
  onClose,
  children,
}: Readonly<NotificationModalViewProps>) {
  const header = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        flex: 'auto',
        px: { xs: 2, md: 8 },
        pt: 3,
        pb: 3,
        '> .MuiButtonBase-root': {
          color: 'black',
          backgroundColor: 'white',
        },
      }}
    >
      <Text variant="h2" text={headingText} color="#fff" lineHeight={1.5} />
      <IconButton
        variant="contained"
        color="primary"
        onClick={onClose}
        disabled={false}
        icon={<Close />}
      />
    </Stack>
  );

  const footer = (
    <Stack
      direction="row"
      justifyContent="center"
      flex="auto"
      sx={{
        pt: 1,
        px: { xs: 2, md: 8 },
        pb: { xs: 3, md: 1 },
      }}
    >
      <Button variant="clear" label={closeButtonLabel} onClick={onClose} />
    </Stack>
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      paperSx={{
        backgroundColor: '#1B1D37',
        borderRadius: '10px',
        border: 'none',
      }}
      header={header}
      footer={footer}
    >
      <Stack
        component="div"
        direction="column"
        spacing={1}
        sx={{
          px: { xs: 2, md: 8 },
          mb: 2,
        }}
      >
        {children}
      </Stack>
    </BasicModal>
  );
}
