import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { browserHistory } from '../browserHistory';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_AZURE_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (process.env.NODE_ENV !== 'test') {
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
