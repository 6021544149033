import { format } from 'date-fns';

export interface GetSessionDateAndDurationProps {
  sessionStart: string;
  sessionEnd: string;
  locale: Locale;
}

export function getSessionDateAndDuration({
  sessionStart,
  sessionEnd,
  locale,
}: GetSessionDateAndDurationProps) {
  const ampm = new Date(sessionStart).getHours() < 12 ? 'am' : 'pm';
  const start = format(new Date(sessionStart), 'h:mm', { locale }) + ampm;

  const durationInMinutes = Math.abs(
    (new Date(sessionEnd).getTime() - new Date(sessionStart).getTime()) / 60000,
  );
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  var duration;

  if (hours === 0) {
    duration = `${minutes} minutes`;
  } else if (hours === 1 && minutes === 0) {
    duration = `${hours} hour`;
  } else if (hours === 1) {
    duration = `${hours} hour ${minutes} minutes`;
  } else if (minutes === 0) {
    duration = `${hours} hours`;
  } else {
    duration = `${hours} hours ${minutes} minutes`;
  }

  return (
    format(new Date(sessionStart), 'EEEE, d MMM', { locale }) +
    ' at ' +
    start +
    ' • ' +
    duration
  );
}
