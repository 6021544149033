/* eslint-disable camelcase */

//external
import { Navigate, Route, Routes } from 'react-router-dom';

// pages
import { AuthenticatedWrapper, RelationshipsWrapper } from 'components';
import { useMixpanelEvents } from 'hooks';
import {
  CompleteProfilePage,
  DashboardPage,
  ErrorPage,
  GuideRegistrationPendingPage,
  GuideRegistrationPublishedPage,
  HelpPage,
  HomePage,
  LearningHubItemPage,
  LearningHubPage,
  LegalPageCookies,
  LegalPagePrivacy,
  LoginPage,
  LogoutPage,
  OnboardingPage,
  OnboardingPictureUploadPage,
  OnboardingProfilePage,
  PaginationDemoPage,
  ProgramLandingPage,
  ProgramManageGuidePage,
  ProgramRegistrationAboutYouPage,
  ProgramRegistrationGoalsPage,
  ProgramRegistrationPage,
  ProgramRegistrationPreviewPage,
  ProgramsListPage,
  RegisterAccountPage,
  RegisterPage,
  SettingsAccountPage,
  SettingsAvailabilityPage,
  SettingsCommunicationPage,
  SettingsIntegrationsPage,
  SettingsPage,
  SettingsProfilePage,
  TraineeRegistrationChoosePage,
  VideoPage,
  GoalsPage,
} from 'pages';
import LegalPageTerms from 'pages/LegalPages/LegalPageTerms';

export const CustomRoutes = () => {
  useMixpanelEvents({ eventName: 'Page View' });

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/*  Authenticated Pages */}
      <Route
        path="/dashboard"
        element={<AuthenticatedWrapper element={<DashboardPage />} />}
      />
      <Route path="/programs">
        <Route path=":programSlug">
          <Route
            path="manage/:role"
            element={
              <AuthenticatedWrapper element={<ProgramManageGuidePage />} />
            }
          ></Route>

          <Route
            path="join"
            element={
              <AuthenticatedWrapper element={<ProgramRegistrationPage />} />
            }
          >
            <Route path=":role">
              <Route path="goals" element={<ProgramRegistrationGoalsPage />} />
              <Route
                path="preview"
                element={<ProgramRegistrationPreviewPage />}
              />
              <Route
                path="membership-pending"
                element={<GuideRegistrationPendingPage />}
              />
              <Route
                path="published"
                element={<GuideRegistrationPublishedPage />}
              />

              <Route
                path="choose"
                element={<TraineeRegistrationChoosePage />}
              />
              <Route path="" element={<ProgramRegistrationAboutYouPage />} />
            </Route>
            <Route path="" element={<Navigate to="../.." />} />
          </Route>
          <Route path="" element={<ProgramLandingPage />} />
        </Route>
        <Route
          path=""
          element={<AuthenticatedWrapper element={<ProgramsListPage />} />}
        />
        <Route path="*" element={<ErrorPage error={404} />} />
      </Route>
      <Route
        path="/relationships"
        element={<AuthenticatedWrapper element={<RelationshipsWrapper />} />}
      >
        <Route path=":relationshipId">
          <Route path="goals">
            <Route path="create" />
            <Route path=":goalId">
              <Route path="edit" />
            </Route>
          </Route>
          <Route path="notes">
            <Route path="create" />
            <Route path=":noteId">
              <Route path="edit" />
            </Route>
          </Route>
          <Route path="sessions">
            <Route path="create" />
            <Route path="log" />
            <Route path=":sessionId">
              <Route path="edit" />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route
        path="/goals"
        element={<AuthenticatedWrapper element={<GoalsPage />} />}
      />
      <Route
        path="/settings"
        element={<AuthenticatedWrapper element={<SettingsPage />} />}
      >
        <Route path="account" element={<SettingsAccountPage />} />
        <Route path="communication" element={<SettingsCommunicationPage />} />
        <Route
          path="profile"
          element={
            <SettingsProfilePage
              getSanityBaseLanguageSilently
              getSanityOrganizationSilently
              getProfilesSilently
              getCustomFieldsSilently
            />
          }
        />
        <Route path="integrations" element={<SettingsIntegrationsPage />} />
        <Route path="availability" element={<SettingsAvailabilityPage />} />
        <Route path="" element={<Navigate to="/settings/profile" />} />
        <Route path="*" element={<Navigate to="/settings/profile" />} />
      </Route>
      <Route
        path="/video/:id"
        element={<AuthenticatedWrapper element={<VideoPage />} />}
      />
      <Route
        path="/complete-profile"
        element={<AuthenticatedWrapper element={<CompleteProfilePage />} />}
      />

      <Route
        path="/learn"
        element={<AuthenticatedWrapper element={<LearningHubPage />} />}
      />
      <Route
        path="/learn/:itemSlug"
        element={<AuthenticatedWrapper element={<LearningHubItemPage />} />}
      />

      {/* Unauthenticated pages */}
      <Route path="/login" element={<LoginPage />} />

      <Route path="/register" element={<RegisterPage />}>
        <Route path="account" element={<RegisterAccountPage />} />
        <Route element={<OnboardingPage />}>
          <Route path="" element={<OnboardingProfilePage />} />
          <Route path="picture" element={<OnboardingPictureUploadPage />} />
        </Route>
      </Route>

      <Route path="/legal">
        <Route path="/legal/privacy" element={<LegalPagePrivacy />} />
        <Route path="/legal/terms" element={<LegalPageTerms />} />
        <Route path="/legal/cookies" element={<LegalPageCookies />} />
        <Route index path="/legal/*" element={<ErrorPage error={404} />} />
      </Route>

      <Route path="/help" element={<HelpPage />} />

      <Route path="/*" element={<ErrorPage error={404} />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/error" element={<ErrorPage error={404} />} />
      <Route path="/demo/pagination" element={<PaginationDemoPage />} />
    </Routes>
  );
};

export default CustomRoutes;
